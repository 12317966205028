<template>
  <div v-bind="$attrs" class="space-y-4">
    <slot v-if="!hideInput" name="input" v-bind="{ updateRootList }">
      <FeedCommentFormSkeletonLoader v-if="authStore?.refreshing" />
      <FeedCommentAddForm
        v-else
        :user="userEntity"
        :feed-id="feedId"
        :feed-type="feedType"
        @comment="(c) => updateRootList(c)"
        sync
      />
    </slot>
    <template v-if="pending">
      <FeedCommentsSkeletonLoader />
    </template>
    <template v-else>
      <slot v-if="commentsList.length === 0" name="empty">
        <p class="text-center text-neutral-700 dark:text-neutral-300 my-6">
          No comments
        </p>
      </slot>
      <ul v-else class="space-y-4 sm:mx-auto" ref="feedListContainer">
        <li
          v-for="(comment, index) in commentsList"
          :key="comment.parent.id"
          :id="`feed-item-${index}`"
        >
          <FeedCommentContent
            v-show="
              !comment.parent.attributes.deletedAt ||
              comment.parent.relationships.filter(isComment).length > 0
            "
            :comment="comment.parent"
            :replies="comment.replies.length"
            :feedType="feedType"
            :feedId="feedId"
            :disableActions="isPerformingAction || deletePending"
            :disableReplyActions="disableReplies"
            :highlightAuthorIds
            :highlightAuthorTag
            @delete="doDelete"
            @forceLoad="doRestore(comment.parent)"
            @update="updateComment"
            @postReply="updateComment"
            @report="
              () => {
                selectedCommentId = comment.parent.id;

                if (!user) showNotLoggedInModal = true;
                else showReportModal = true;
              }
            "
          >
            <template
              #replies
              v-if="
                comment.parent.relationships.filter(isComment).length > 0 ||
                comment.replies.length > 0
              "
            >
              <!-- Load earlier replies button/loading -->
              <div
                class="flex"
                v-if="
                  comment.parent.relationships.filter(isComment).length >
                    comment.replies.length && !comment.repliesLoading
                "
              >
                <div class="w-10 min-w-[40px] flex justify-center">
                  <div
                    class="bg-neutral-300 dark:bg-neutral-700 w-[2px] lg:w-[4px] h-full"
                  ></div>
                </div>
                <NamiButton
                  buttonType="primary"
                  text
                  block
                  small
                  @buttonClick="loadReplies(comment.parent)"
                >
                  Load earlier replies
                </NamiButton>
              </div>

              <div v-else-if="comment.repliesLoading" class="space-y-2">
                <FeedCommentsSkeletonLoader
                  v-for="n in Math.min(
                    comment.parent.relationships.filter(isComment).length -
                      comment.replies.length,
                    2,
                  )"
                />
              </div>

              <!-- Reply list -->
              <ul class="flex flex-col-reverse">
                <li
                  v-for="reply in comment.replies"
                  :key="reply.id"
                  class="pt-1"
                >
                  <!-- Reply content -->
                  <FeedCommentContent
                    :comment="reply"
                    :replies="0"
                    :feedType="feedType"
                    :feedId="feedId"
                    :parentId="comment.parent.id"
                    :disableActions="isPerformingAction"
                    :disableReplyActions="disableReplies"
                    :highlightAuthorIds
                    :highlightAuthorTag
                    show-reply-line
                    @delete="doDelete"
                    @forceLoad="doRestore"
                    @update="updateComment"
                    @postReply="updateComment"
                    @report="
                      () => {
                        selectedCommentId = comment.parent.id;

                        if (!user) showNotLoggedInModal = true;
                        else showReportModal = true;
                      }
                    "
                  />
                </li>
              </ul>
            </template>
          </FeedCommentContent>
        </li>
      </ul>
      <div v-if="commentsList.length < commentTotal" class="my-4">
        <NamiButton
          v-if="!moreCommentsLoading"
          buttonType="primary"
          text
          block
          @buttonClick="loadMoreComments"
        >
          Show more
        </NamiButton>
        <div v-if="moreCommentsLoading" class="space-y-4">
          <FeedCommentsSkeletonLoader
            v-for="n in Math.min(
              commentTotal - commentsList.length,
              loadMoreLimit,
            )"
          />
        </div>
      </div>
    </template>
  </div>
  <ReportModal
    v-model="showReportModal"
    entityType="comment"
    :entityId="selectedCommentId"
  />
</template>

<script lang="ts" setup>
import { IconTrash } from "@tabler/icons-vue";
import {
  Comment,
  isComment,
  getRelationship,
  Reaction,
  type CommentableResource,
  type CommentEntity,
  type UserEntity,
} from "~/src/api";

import { bulkAddCommentMeta } from "~/composables/comment/useCommentMeta";

interface Props {
  feedType: CommentableResource;
  feedId: string;
  highlightAuthorIds?: string[];
  noComments?: boolean;
  hideInput?: boolean;
  disableReplies?: boolean;
  highlightAuthorTag?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "commentPost"): void;
  (e: "commentDelete"): void;
}>();

const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const feedsStore = nuxtApp.$feeds();
const appStore = nuxtApp.$app();

const user = computed(() => authStore?.user);
const userEntity = computed<UserEntity | undefined>(
  () => authStore?.userEntity || undefined,
);

const loadMoreLimit = 10;

const showNotLoggedInModal = ref(false);
const showReportModal = ref(false);
const selectedCommentId = ref("");

type ParentComment = {
  parent: CommentEntity;
  replies: CommentEntity[];
  repliesLoading: boolean;
};

const commentsList = reactive<ParentComment[]>([]);
const commentsMeta = reactive<{ total: number; offset: number; limit: number }>(
  {
    total: 0,
    offset: 0,
    limit: 50,
  },
);
const commentTotal = computed(() => commentsMeta.total ?? 0);

const updateRootList = (...args: CommentEntity[]) => {
  commentsList.unshift(
    ...args.map((c) => ({
      parent: c,
      replies: [],
      repliesLoading: false,
    })),
  );
};

const { pending } = useAsyncData(
  `feed-${props.feedType}-${props.feedId}`,
  async () => {
    if (props.noComments) return;
    const res = await Comment.search(props.feedType, props.feedId, {
      parentId: "NULL",
      order: { createdAt: "desc" },
      includes: ["user", "organization", "profile_frame", "badge"],
    });

    commentsList.splice(0, commentsList.length);

    const parentCommentIds = res.data.map((x) => x.id);

    const [reactions, userReactions] = await Promise.all([
      Reaction.getReactionSummaryBatch("comment", {
        entityIds: parentCommentIds,
      }),
      authStore?.userEntity
        ? Reaction.findReactions("comment", {
            userIds: [authStore.userEntity.id],
            entityIds: parentCommentIds,
            limit: parentCommentIds.length,
          })
        : null,
    ]);

    // create an array of reaction data
    const mappedMeta = parentCommentIds.map((id) => {
      // get the reaction for the specified comment id
      const associatedReactions = reactions.data.find(
        ({ id }) => id.replace("comment-", "") === id,
      );

      // format the response
      return {
        commentId: id,
        userReaction: userReactions
          ? (userReactions.data.find(
              (r) => getRelationship(r, "comment").id === id,
            )?.attributes.reactionType ?? null)
          : null,
        allReactions: associatedReactions?.attributes.reactions ?? {},
      };
    });

    // update meta db
    await bulkAddCommentMeta(mappedMeta);

    res.data.forEach((comment) => {
      const user = getRelationship(comment, "user");

      if (user && user.attributes)
        feedsStore?.updateUserDict(user.id, user.attributes.username);

      commentsList.push({
        parent: comment,
        replies: [],
        repliesLoading: false,
      });
    });

    commentsMeta.limit = res.meta.limit;
    commentsMeta.offset = res.meta.offset;
    commentsMeta.total = res.meta.total;
  },
  { watch: [() => props.feedId, () => props.noComments] },
);

const loadMoreComments = async () => {
  moreCommentsLoading.value = true;

  const res = await Comment.search(props.feedType, props.feedId, {
    parentId: "NULL",
    order: { createdAt: "desc" },
    includes: ["user", "organization", "profile_frame", "badge"],
    offset: commentsList.length,
    limit: loadMoreLimit,
  });

  const parentCommentIds = res.data.map((x) => x.id);

  if (!parentCommentIds.length) {
    moreCommentsLoading.value = false;
    return;
  }

  const [reactions, userReactions] = await Promise.all([
    Reaction.getReactionSummaryBatch("comment", {
      entityIds: parentCommentIds,
    }),
    authStore?.userEntity
      ? Reaction.findReactions("comment", {
          userIds: [authStore.userEntity.id],
          entityIds: parentCommentIds,
          limit: parentCommentIds.length,
        })
      : null,
  ]);

  // create an array of reaction data
  const mappedMeta = parentCommentIds.map((id) => {
    // get the reaction for the specified comment id
    const associatedReactions = reactions.data.find(
      ({ id }) => id.replace("comment-", "") === id,
    );

    // format the response
    return {
      commentId: id,
      userReaction: userReactions
        ? (userReactions.data.find(
            (r) => getRelationship(r, "comment").id === id,
          )?.attributes.reactionType ?? null)
        : null,
      allReactions: associatedReactions?.attributes.reactions ?? {},
    };
  });

  // update meta db
  await bulkAddCommentMeta(mappedMeta);

  res.data.forEach((comment) => {
    const user = getRelationship(comment, "user");
    if (user && user.attributes)
      feedsStore?.updateUserDict(user.id, user.attributes.username);

    commentsList.push({
      parent: comment,
      replies: [],
      repliesLoading: false,
    });
  });
  moreCommentsLoading.value = false;
};

const loadReplies = async (parent: CommentEntity) => {
  const foundRoot = commentsList.find(
    ({ parent: root }) => root.id === parent.id,
  );

  if (!foundRoot) return;

  foundRoot.repliesLoading = true;

  await Comment.loadMoreReplies(parent, foundRoot.replies.length).then(
    async (res) => {
      res.data.forEach((comment) => {
        const user = getRelationship(comment, "user");
        if (user && user.attributes)
          feedsStore?.updateUserDict(user.id, user.attributes.username);
      });

      const replyIds = res.data.map((x) => x.id);

      const [reactions, userReactions] = await Promise.all([
        Reaction.getReactionSummaryBatch("comment", {
          entityIds: replyIds,
        }),
        authStore?.userEntity
          ? Reaction.findReactions("comment", {
              userIds: [authStore.userEntity.id],
              entityIds: replyIds,
              limit: replyIds.length,
            })
          : null,
      ]);

      // create an array of reaction data
      const mappedMeta = replyIds.map((id) => {
        // get the reaction for the specified comment id
        const associatedReactions = reactions.data.find(
          ({ id }) => id.replace("comment-", "") === id,
        );

        // format the response
        return {
          commentId: id,
          userReaction: userReactions
            ? (userReactions.data.find(
                (r) => getRelationship(r, "comment").id === id,
              )?.attributes.reactionType ?? null)
            : null,
          allReactions: associatedReactions?.attributes.reactions ?? {},
        };
      });

      // update meta db
      await bulkAddCommentMeta(mappedMeta);

      foundRoot.replies.push(...res.data);

      foundRoot.repliesLoading = false;
    },
  );
};

const moreCommentsLoading = ref(false);
const { isPerformingAction, startAction, endAction } = useAction();

async function updateComment(comment: CommentEntity) {
  // this is a child comment
  if (comment.attributes.order % 10000 !== 0) {
    const parent = comment.relationships.find(isComment);
    assertDefined(parent);

    const foundParent = commentsList.find(
      (root) => root.parent.id === parent.id,
    );
    assertDefined(foundParent);

    const foundCommentIndex = foundParent.replies.findIndex(
      (repl) => repl.id === comment.id,
    );
    if (foundCommentIndex < 0) {
      foundParent.replies.unshift(comment);
    } else foundParent.replies.splice(foundCommentIndex, 1, comment);
  } else {
    const foundParent = commentsList.find(
      (root) => root.parent.id === comment.id,
    );

    assertDefined(foundParent);

    foundParent.parent = comment;
  }
}

const { action: doDelete, pending: deletePending } = useAction2(
  async (comment: CommentEntity) => {
    const res = await appStore?.prompt(
      "Are you sure you want to delete this comment?",
      {
        icon: "confused",
        buttons: {
          confirm: {
            buttonType: "danger",
            buttonText: "Delete",
            icon: IconTrash,
          },
          cancel: {
            buttonType: "secondary",
            buttonText: "Cancel",
          },
        },
      },
    );

    if (res !== "confirm") return;

    appStore?.notify({
      preset: "loading.plain",
      detail: "Deleting comment...",
      timer: 3000,
    });

    // 1: locate the comment position
    const commentParent = commentsList.find((root) =>
      root.replies.some((repl) => repl.id === comment.id),
    )!;

    const commentIndex =
      commentParent?.replies.findIndex((repl) => repl.id === comment.id) ??
      commentsList.findIndex((root) => root.parent.id === comment.id);

    if (commentIndex < 0) return;

    let removed: ParentComment | undefined;

    await executeWithNotificationOnError(async () => {
      const token = await getTokenOrThrow();

      // 2: immediately remove comment from view
      if (commentParent) {
        commentParent.replies[commentIndex].attributes.deletedAt =
          new Date().toString();
      } else {
        removed = commentsList.splice(commentIndex, 1)[0];
      }

      // 3: then attempt to delete
      await Comment.delete(comment.id, comment.attributes.version, token);
      if (commentParent)
        commentParent.replies[commentIndex].attributes.version += 1;

      appStore?.closeAllNotifications();
      appStore?.notify({
        preset: "success.plain",
        detail: "Comment deleted.",
        timer: 3000,
      });

      emit("commentDelete");
    }).catch(() => {
      // E: if it failed, revert the removal
      if (removed) {
        commentsList.splice(commentIndex, 0, removed);
      } else {
        commentParent.replies[commentIndex].attributes.deletedAt = null;
      }
    });
  },
);

async function doRestore(comment: CommentEntity) {
  startAction();

  if (!user.value) {
    appStore?.openLoginRequiredModal();
    return endAction();
  }

  await executeWithNotificationOnError(async () => {
    const token = await getTokenOrThrow();

    await Comment.restore(comment.id, comment.attributes.version, token);

    const newComment = await Comment.get(comment.id, ["user"], token);

    emit("commentPost");

    // this will always be a reply comment
    const foundParent = commentsList.find((root) =>
      root.replies.some((repl) => repl.id === comment.id),
    );

    assertDefined(foundParent);

    const index = foundParent.replies.findIndex(
      (repl) => repl.id === comment.id,
    );

    foundParent.replies.splice(index, 1, newComment);
  }).catch(() => {});

  endAction();
}

const feedListContainer = ref<HTMLUListElement>();
const feedItemsObserver = new IntersectionObserver((entries) => {
  entries.forEach(async (entry) => {
    const index = parseInt(
      entry.target.id.match(/^feed-item-(\d+)$/)?.at(1) ?? "-1",
    );
    if (index === -1)
      throw new Error(
        `Feed item must have an id that matches the regex pattern /^feed-item-(\\d+)$/`,
      );

    if (entry.isIntersecting) {
      const currentComment = commentsList[index];
      if (
        currentComment.replies.length === 0 &&
        !currentComment.repliesLoading
      ) {
        currentComment.repliesLoading = true;
        await loadReplies(currentComment.parent);
        currentComment.repliesLoading = false;
      }
    }
  });
});

watch(
  () => feedListContainer.value?.children.length,
  () => {
    if (!feedListContainer.value) return;

    feedItemsObserver.disconnect();

    Array.from(feedListContainer.value.children).forEach((child) =>
      feedItemsObserver.observe(child),
    );
  },
);
</script>
