<template>
  <div class="grid grid-cols-[36px_1fr] gap-x-2 pt-1">
    <div class="h-[36px] w-[36px] rounded-full overflow-hidden">
      <Skeleton class="h-full w-full" />
    </div>
    <div class="grid">
      <Skeleton class="w-48 h-4 my-auto" />
      <Skeleton class="w-24 h-2 my-auto" />
    </div>
    <div></div>
    <Skeleton class="h-7 mt-2" />
    <div></div>
    <div class="flex space-x-2 mt-2">
      <Skeleton class="w-16 h-6" />
      <Skeleton class="w-6 h-6" />
      <Skeleton class="w-6 h-6" />
    </div>
  </div>
</template>
